import { downgradeInjectable } from '@angular/upgrade/static';
import { OauthService } from '@monsido/oauth/oauth.service';
import { SessionService } from '@monsido/core/session/session.service';
import { LocalStorageService, SessionStorageService, ToastService, MonPromptService, LoadingService } from '@angular-shared-components';
import { MonLoadingService } from '@monsido/services/loading/services/loading.service';
import { CoreAgreementService } from '@monsido/core/session/services/agreement/agreement.service';
import { MonEventService } from '@monsido/services/mon-event/mon-event.service';
import { AccessibilityGuidelineVersionService } from '@monsido/modules/accessibility/services/accessibility-guideline-version.service';
import { AccessibilityGuidelinesInfoService } from '../modules/accessibility/services/mon-accessibility-guidelines/accessibility-guideline-info.service';
import { ScoresService } from '../modules/score/services/scores/scores.service';
import { MonBrowserService } from '@monsido/services/mon-browser/mon-browser.service';
import { ConfigurableFocusTrapFactory } from '@angular/cdk/a11y';
import angular from 'angular';
import { DataPrivacyLikelihoodService } from '@monsido/modules/data-privacy/services/data-privacy-likelihood.service';
import { DirectNavigationService } from '@monsido/services/direct-navigation-service/direct-navigation.service';
import { ExportService } from '@monsido/core/services/export.service';
import { StatisticsDateComparisonService } from '@monsido/modules/statistics/services/date-comparison/statistics-date-comparison.service';
import { MonNotificationsService } from 'ng2/core/services/notifications/mon-notifications.service';
import { DebugLogService } from 'ng2/core/services/debug-log/debug-log.service';
import { HttpLegacyService } from '@monsido/http/http-legacy-service/http-legacy.service';
import { LabelService } from '@monsido/services/label-service/label.service';
import {
    FormScheduleReportService,
} from '@monsido/modules/report-center/services/form-schedule-report/form-schedule-report.service';
import { MonUIRouterTransitionService } from '@monsido/services/ui-router-transition-service/ui-router-transition-service';
import { RollbarErrorHandlerService } from 'ng2/external/rollbar/rollbar-error-handler.service';
import { MonLogService } from 'ng2/services/mon-log-service/mon-log.service';
import { DefaultConstraintsAndExcludesService } from 'app/forms/domain/default-constraints-and-excludes-service/default-constraints-and-excludes.service';
import { ParamService } from '@monsido/ng2/core/param/param.service';
import { ActiveFeatureService } from '@monsido/services/active-feature/active-feature.service';
import { DateTimeService } from 'app/services/date-time/date-time.service';
import { FormDomainScriptPageAssistService } from 'app/forms/domain-script-guide/models/page-assist/form-domain-script-page-assist.service';
import { FastTrackSessionService } from '../modules/fast-track/services/session.service';
import { MonUtilsService } from 'app/blocks/service/mon-utils/mon-utils.service';
import { PrivacyFastTrackModelFactoryService } from 'app/modules/data-privacy/services/privacy-fast-track-model-factory/privacy-fast-track-model-factory.service';
import { CacheService } from 'app/modules/cache/cache.service';
import { DemoPageClarityRepoService } from '../modules/demodata/endpoints/pages/demo-page-clarity-repo/demo-page-clarity-repo.service';
import { DomainsService } from 'app/services/domains/domains.service';
import { LightPageFixService } from 'app/modules/light/services/light-page-fix/light-page-fix.service';
import { PageModelFactoryService } from '../models/page-model-factory.service';
import { DeeplinkService } from 'app/modules/deeplink/services/deeplink/deeplink.service';
import { MonModalService } from 'app/modules/modals/services/mon-modal/mon-modal.service';
import { DemoPdfRepoService } from 'app/modules/demodata/endpoints/demo-pdf-repo/demo-pdf-repo.service';
import { ApiService } from 'app/services/api/api-service/api.service';
import { GlobalHelperService } from 'app/services/global-helper/global-helper.service';
import { DemoPageRepoService } from '../modules/demodata/endpoints/pages/demo-page-repo.service';
import { ErrorHandler } from 'app/core/services/error-handler/error-handler';
import { DemoHasAccessService } from 'app/modules/demodata/services/demo-has-access.service';
import { MeRepoService } from 'app/services/api/me-repo/me-repo.service';
import { AuditlogModulesService } from 'app/services/auditlog-modules/auditlog-modules.service';
import { LegendCursorService } from 'deprecate/frontend-components/src/js/components/charts/services/LegendCursor.service';
import { DomainRepoService } from 'app/services/api/domain-repo/domain-repo.service';
import { UptimeRepoService } from 'app/services/api/uptime-repo/uptime-repo.service';
import { AgreementRepoService } from 'app/services/api/agreement-repo/agreement-repo.service';
import { CheckForDuplicatesService } from '../core/services/check-for-duplicates/check-for-duplicates.service';
import { DeviceDetectorService } from '../modules/extension/services/device-detector.service';
import { FormDomainScriptGuidePageAssistService } from '../forms/domain-script-guide/features/page-assist/form-domain-script-guide-page-assist.service';
import { HistoryHelperService } from 'app/blocks/helpers/history.helper.service';
import { PropertiesSumService } from 'app/filters/properties-sum.service';
import { LightDomainViewService } from 'app/modules/light/services/lightDomainView/lightDomainView.service';
import { PageFixFormHtmlElementOptionsService } from 'app/modules/page-fix/services/form/html-element-options.service';
import { SpellingToolRepoService } from '../services/api/spelling-tool-repo/spelling-tool-repo.service';
import { AccessibilityChecksMapService } from 'app/modules/page-fix/components/accessibility-checks/accessibility-checks-map/accessibility-checks-map.service';
import { TextSearchService } from '@monsido/ng2/services/text-search/text-search.service';
import { DomainGroupUpdateInfoService } from '../services/entities/domain-group/domain-group-update-info/domain-group-update-info.service';
import { DemoPdfClarityRepoService } from '../modules/demodata/endpoints/demo-pdf-clarity-repo/demo-pdf-clarity-repo.service';
import { CookieBannerRepoService } from '../modules/cookie-banner/services/cookie-banner-repo.service';
import { LoginService } from '@client/app/core/services/login/login.service';
import { DomainService } from 'app/services/entities/domain/domain.service';
import { DemoSupportCenterAccessibilityChecksRepoService } from '../modules/supportcenter/services/demo-supportcenter-accessibility-checks-repo/demo-support-center-accessibility-checks-repo.service';
import { SupportFeedbackRepoService } from '../services/api/support-feedback-repo/support-feedback-repo.service';
import { GlobalAccessibilityChecksRepoService } from '../services/accessibility-checks-repo/GlobalAccessibilityChecksRepo.service';
import { DataPrivacyInfoTypeRepoService } from '../modules/data-privacy/services/api/info-type/data-privacy-info-type-repo.service';
import { AuditlogService } from '../pages/admin/auditlog/auditlog.component.service';
import { NotificationRepoService } from '../services/api/notification-repo/notification-repo.service';
import { DataPrivacyRulesetRepo } from '../modules/data-privacy/services/api/ruleset_repo';
import { AccessibilityGuidelinesRepoService } from '@client/app/services/api/accessibility/accessibility-guidelines-repo.service';
import { SupportRequestRepoService } from '../services/api/support-request-repo/support-request-repo.service';
import { AccessibilityRepoService } from '@client/app/services/api/accessibility-repo/accessibility-repo.service';
import { MeReportingsRepoService } from '@client/app/services/api/me-reportings-repo/me-reportings-repo.service';
import { AuditRepoService } from '@client/app/services/api/audit-repo/audit-repo.service';
import { DataPrivacySummaryRepo } from '../modules/data-privacy/services/api/summary_repo';
import { ReportRepoService } from '@client/app/services/api/report-repo/report-repo.service';
import { UserRepoService } from '../services/api/user-repo/user-repo.service';
import { PageRepoService } from '@client/app/services/api/page-repo/page-repo.service';
import { SearchDomainsService } from '../layout/menu/side-menu-search/domain-panel/search-domains/search-domains.service';
import { GuidelineInfoService } from '@monsido/ng2/modules/accessibility/services/guideline-info/guideline-info.service';
import { UserLocaleService } from '../blocks/service/users/locale.service';
import { MeService } from '../services/entities/me/me.service';
import { DemoStatsRepoService } from '@client/app/modules/demodata/endpoints/pages/demo-stats-repo/demo-stats-repo.service';
import { ExtensionService } from '@client/app/modules/extension/services/extension.service';
import { StatsRepoService } from '../services/api/stats-repo/stats-repo.service';
import { RedirectWhitelistHelperService } from '../core/services/redirect-whitelist/redirect-whitelist-helper.service';
import { AccessibilityCheckNameService } from '../modules/accessibility/services/accessibility-check-name/accessibility-check-name.service';
import { FormDomainScriptCookieBannerService } from '@client/app/forms/domain-script-guide/models/cookie-banner/form-domain-script-cookie-banner.service';
import { TotalDomainsService } from '../services/total-domains/total-domains.service';
import { ProfilesRepoService } from '../services/api/page-performance/profiles-repo/profiles-repo.service';
import { PagePerformanceRescanRepoService } from '../services/api/page-performance/page-performance-rescan-repo/page-performance-rescan-repo.service';
import { HeatmapsRepoService } from '../services/api/heatmaps-repo/heatmaps-repo.service';
import { HelpCenterRepoService } from '../modules/data-privacy/services/api/help-center-repo/help-center-repo.service';
import { AccountRepoService } from '../services/api/account-repo/account-repo.service';
import { PagePerformanceReportRepoService } from '../services/api/page-performance/page-performance-report/page-performance-report-repo.service';
import { PagePerformanceResultsRepoService } from '../services/api/page-performance/page-performance-results-repo/page-performance-results-repo.service';
import { QualityRepoService } from '../services/api/quality-repo/quality-repo.service';
import { ReadabilityRepoService } from '@client/app/services/api/readability-repo/readability-repo.service';
import { SeoRepoService } from '../services/api/seo-repo/seo-repo.service';
import { FormDomainScriptGuideService } from '@client/app/forms/domain-script-guide/form-domain-script-guide.service';
import { DomainGroupRepoService } from '../services/api/domain-groups-repo/domain-group-repo.service';
import { PageClarityRepoService } from '../services/api/page-clarity/page-clarity-repo.service';
import { ClarityRepoService } from '../services/api/clarity-repo/clarity-repo.service';
import { LabelRepo } from '@monsido/modules/endpoints/api/label.repo';
import { BrokenPageRepoService } from '@client/app/services/api/broken-page-repo/broken-page-repo.service';
import { PageAccessibilityRepoService } from '../services/api/page-accessibility/page-accessibility-repo.service';
import { PagePolicyRepoService } from '../services/api/page-policy/page-policy-repo.service';
import { LinkPagesRepoService } from '@client/app/services/api/link-pages-repo/link-pages-repo.service';
import { ShortcutsRepoService } from '@client/app/services/api/shortcuts-repo/shortcuts-repo.service';
import { PdfRepoService } from '../services/api/pdf-repo/pdf-repo.service';
import { PagePerformanceAuditDetailsService } from '../modules/page-performance/services/page-performance-audit-details/page-performance-audit-details.service';
import { AccessibilityAuxService } from '../pages/accessibility/services/accessibility-aux/accessibility-aux.service';
import { ChangesHistoryHelperService } from '../pages/global/changes-history/services/changes-history-helper/changes-history-helper.service';
import { DisplayHelperService } from '../modules/page-performance/components/display-modes/shared/display-helper.service';
import { DemoHeatmapsRepoService } from '../modules/demodata/endpoints/demo-heatmaps-repo/demo-heatmaps-repo.service';
import { AdminDomainsService } from '../pages/admin/domains/admin-domains-service/admin-domains.service';
import { FastTrackTableService } from '../modules/fast-track/table/fast-track-table.service';
import { GlobalPolicyExchangeCenterTemplateService } from '../modules/global-policy/services/global-policy-exchange-center-template.service';
import { CoreDomainService } from '../core/services/domain/core-domain.service';
import { CrawlsRepoService } from '../services/api/crawls-repo/crawls-repo.service';
import { SpellingRepoService } from '../services/api/spelling-repo/spelling-repo.service';
import { CookiesRepoService } from '@client/app/services/api/cookies-repo/cookies-repo.service';
import { PolicyRepoService } from '../services/api/policy-repo/policy-repo.service';
import { PageFixRepoService } from '../services/api/pagefix-repo/page-fix-repo.service';
import { SupportAccessibilityChecksRepoService } from '../modules/supportcenter/services/api/accessibility-checks/support-accessibility-checks-repo.service';
import { TrapFocusService } from '../modules/trap-focus/trap-focus.service';
import { EditAltTextService } from '../modules/page-fix/services/form/edit-alt-text/edit-alt-text.service';
import { CrawlHistoryInfoService } from '../core/services/crawl-history-info/crawl-history-info.service';
import { PagePageLinksRepoService } from '@client/app/services/api/pages/page-links-repo.service';
import { PageFixAccessibilityConfigService } from '../modules/page-fix/services/page-fix-accessibility-config/page-fix-accessibility-config.service';
import { LinksRepoService } from '../services/api/links-repo/links-repo.service';
import { PagePerformanceService } from '../modules/page-performance/services/page-performance/page-performance.service';
import { PagePerformancePageDetailsService } from '../modules/page-performance/services/page-performance-page-details/page-performance-page-details.service';
import { AccessibilityAutofixService } from '../modules/page-fix/services/accessibility-auto-fix/accessibility-auto-fix.service';
import { DataPrivacyViolationRepoService } from '../modules/data-privacy/services/api/data-privacy-violation-repo/data-privacy-violation-repo.service';
import { DataPrivacyContentRepoService } from '../modules/data-privacy/services/api/content-repo/data-privacy-content-repo.service';
import { DataPrivacyService } from '../modules/data-privacy/services/data-privacy/data-privacy.service';
import { SEOLineChartFactory } from '../blocks/service/charts/seo-line-chart.service';
import { AccessibilityChecksRepoService } from '../services/api/accessibility/accessibility-checks-repo/accessibility-checks-repo.service';
import { FormPagePerformancePageService } from '@client/app/modules/page-performance/forms/page/form-page-performance-page.service';
import { QAReadabilityService } from '../blocks/service/qa-readability/qareadability.service';
import { PagePerformanceAuditInfoService } from '../modules/page-performance/services/page-performance-audit-info/page-performance-audit-info.service';
import {
    LightDomainPageFixDashboardPageFixModelService,
} from '@client/app/modules/light/pages/domain/page-fix/lightDomainPageFixDashboard/models/lightDomainPageFixDashboardPageFixModel/light-domain-page-fix-dashboard-page-fix-model.service';
import { ClarityService } from '@client/app/services/entities/clarity/clarity.service';
import { PageClarityService } from '@client/app/services/entities/clarity/page-clarity.service';
import {
    ChangesHistoryReadabilityService,
} from '@client/app/pages/global/changes-history/services/readability/readability.service';
import {
    DataPrivacyLineChartService,
} from '@client/app/blocks/service/charts/data-privacy-line-chart/data-privacy-line-chart.service';

import { CookieModelService } from '@client/app/modules/cookie-banner/components/cookie-overview/cookie-model.service';
import { PagePerformanceProfileService } from '../services/entities/page-performance/page-performance-profile.service';
import { UserService } from '../services/entities/user/user/user.service';
import { CookieCategoriesLocalizeService } from '../modules/cookie-banner/components/cookie-overview/categories-localize/cookie-categories-localize.service';
import { ComplianceAccessibilityService } from '../blocks/service/compliance/compliance-accessibility/compliance-accessibility.service';
import { PageFixAccessibilityDifficultyService } from '../modules/page-fix/services/accessibility-difficulty/page-fix-accessibility-difficulty.service';
import { PagePerformanceVersionService } from '../modules/page-performance/services/page-performance-version/page-performance-version.service';
import { LanguagesService } from '../modules/languages-ng2/languages.service';
import { PagePerformanceAuditService } from '../modules/page-performance/services/page-performance-audit/page-performance-audit.service';
import { PagePerformanceChartsService } from '../modules/page-performance/components/charts/page-performance-charts/page-performance-charts.service';
import { PolicyLineChartService } from '@client/app/blocks/service/charts/policy-line-chart/policy-line-chart.service';
import { QALineChartService } from '@client/app/blocks/service/charts/qa-line-chart/qa-line-chart.service';
import {
    DataPrivacyFastTrackRepoService,
} from '@client/app/modules/data-privacy/services/api/data-privacy-fast-track-repo/fast-track-repo.service';
import {
    ReadabilityLineChartService,
} from '@client/app/blocks/service/charts/readability-line-chart/readability-line-chart.service';
import { FormUptimeService } from '@client/app/forms/uptime/uptime-service/uptime.service';
import { PolicyFormHelperService } from '@client/app/blocks/helpers/policy-form-helper/policy-form.helper.service';


(function () {
    'use strict';
    angular
        .module('app.core')
        .factory('ng2PolicyFormHelperService', downgradeInjectable(PolicyFormHelperService))
        .factory('ng2AccessibilityChecksMapService', downgradeInjectable(AccessibilityChecksMapService))
        .factory('ng2FormUptimeService', downgradeInjectable(FormUptimeService))
        .factory('ng2DataPrivacyFastTrackRepoService', downgradeInjectable(DataPrivacyFastTrackRepoService))
        .factory('ng2AccessibilityChecksRepoService', downgradeInjectable(AccessibilityChecksRepoService))
        .factory('ng2AgreementRepoService', downgradeInjectable(AgreementRepoService))
        .factory('ng2DemoPdfClarityRepoService', downgradeInjectable(DemoPdfClarityRepoService))
        .factory('ng2DemoPageClarityRepoService', downgradeInjectable(DemoPageClarityRepoService))
        .factory('ng2PrivacyFastTrackModelFactoryService', downgradeInjectable(PrivacyFastTrackModelFactoryService))
        .factory('ng2MonPromptService', downgradeInjectable(MonPromptService))
        .factory('ng2StatisticsDateComparisonService', downgradeInjectable(StatisticsDateComparisonService))
        .factory('ng2ScoresService', downgradeInjectable(ScoresService))
        .factory('ng2MeService', downgradeInjectable(MeService))
        .factory('ng2MonEventsService', downgradeInjectable(MonEventService))
        .factory('ng2SessionService', downgradeInjectable(SessionService))
        .factory('ng2DomainGroupUpdateInfoService', downgradeInjectable(DomainGroupUpdateInfoService))
        .factory('ng2OauthService', downgradeInjectable(OauthService))
        .factory('ng2ToastService', downgradeInjectable(ToastService))
        .factory('ng2SessionStorageService', downgradeInjectable(SessionStorageService))
        .factory('ng2LocalStorageService', downgradeInjectable(LocalStorageService))
        .factory('ng2CoreAgreementService', downgradeInjectable(CoreAgreementService))
        .factory('ng2MonLoading', downgradeInjectable(MonLoadingService))
        .factory('ng2AccessibilityGuidelineVersionService', downgradeInjectable(AccessibilityGuidelineVersionService))
        .factory('ng2AccessibilityGuidelinesInfoService', downgradeInjectable(AccessibilityGuidelinesInfoService))
        .factory('ng2MonBrowserService', downgradeInjectable(MonBrowserService))
        .factory('ng2DirectNavigationService', downgradeInjectable(DirectNavigationService))
        .factory('ng2ExportService', downgradeInjectable(ExportService))
        .factory('ng2ConfigFocusTrapFactory', downgradeInjectable(ConfigurableFocusTrapFactory))
        .factory('ng2FormScheduleReportService', downgradeInjectable(FormScheduleReportService))
        .factory('ng2LabelService', downgradeInjectable(LabelService))
        .factory('ng2MonNotificationsService', downgradeInjectable(MonNotificationsService))
        .factory('ng2DebugLogService', downgradeInjectable(DebugLogService))
        .factory('ng2HttpLegacyService', downgradeInjectable(HttpLegacyService))
        .factory('ng2MonUIRouterTransitionService', downgradeInjectable(MonUIRouterTransitionService))
        .factory('ng2NotificationRepoService', downgradeInjectable(NotificationRepoService))
        .factory('ng2RollbarErrorHandlerService', downgradeInjectable(RollbarErrorHandlerService))
        .factory('ng2MonLogService', downgradeInjectable(MonLogService))
        .factory('ng2DefaultConstraintsAndExcludesService', downgradeInjectable(DefaultConstraintsAndExcludesService))
        .factory('ng2ParamService', downgradeInjectable(ParamService))
        .factory('ng2TextSearchService', downgradeInjectable(TextSearchService))
        .factory('ng2MonPromptService', downgradeInjectable(MonPromptService))
        .factory('ng2ActiveFeatureService', downgradeInjectable(ActiveFeatureService))
        .factory('ng2DateTimeService', downgradeInjectable(DateTimeService))
        .factory('ng2FormDomainScriptPageAssistService', downgradeInjectable(FormDomainScriptPageAssistService))
        .factory('ng2FastTrackSessionService', downgradeInjectable(FastTrackSessionService))
        .factory('ng2MonUtilsService', downgradeInjectable(MonUtilsService))
        .factory('ng2CacheService', downgradeInjectable(CacheService))
        .factory('ng2DomainsService', downgradeInjectable(DomainsService))
        .factory('ng2LightPageFixService', downgradeInjectable(LightPageFixService))
        .factory('ng2PageModelFactoryService', downgradeInjectable(PageModelFactoryService))
        .factory('ng2DeeplinkService', downgradeInjectable(DeeplinkService))
        .factory('ng2MonModalService', downgradeInjectable(MonModalService))
        .factory('ng2DemoPdfRepoService', downgradeInjectable(DemoPdfRepoService))
        .factory('ng2ApiService', downgradeInjectable(ApiService))
        .factory('ng2GlobalHelperService', downgradeInjectable(GlobalHelperService))
        .factory('ng2DemoPageRepoService', downgradeInjectable(DemoPageRepoService))
        .factory('ng2SearchDomainsService', downgradeInjectable(SearchDomainsService))
        .factory('ng2MeRepoService', downgradeInjectable(MeRepoService))
        .factory('ng2SupportFeedbackRepoService', downgradeInjectable(SupportFeedbackRepoService))
        .factory('ng2AuditlogModulesService', downgradeInjectable(AuditlogModulesService))
        .factory('ng2DomainRepoService', downgradeInjectable(DomainRepoService))
        .factory('ng2UptimeRepoService', downgradeInjectable(UptimeRepoService))
        .factory('ng2CheckForDuplicatesService', downgradeInjectable(CheckForDuplicatesService))
        .factory('ng2SupportRequestRepoService', downgradeInjectable(SupportRequestRepoService))
        .factory('ng2SpellingToolRepoService', downgradeInjectable(SpellingToolRepoService))
        .factory('ng2DeviceDetectorService', downgradeInjectable(DeviceDetectorService))
        .factory('ng2FormDomainScriptGuidePageAssistService', downgradeInjectable(FormDomainScriptGuidePageAssistService))
        .factory('ng2TotalDomainsService', downgradeInjectable(TotalDomainsService))
        .factory('ng2CookieBannerRepoService', downgradeInjectable(CookieBannerRepoService))
        .factory('ng2CoreLoginService', downgradeInjectable(LoginService))
        .factory('ng2CDemoSupportCenterAccessibilityChecksRepoService', downgradeInjectable(DemoSupportCenterAccessibilityChecksRepoService))
        .factory('ng2GlobalAccessibilityChecksRepoService', downgradeInjectable(GlobalAccessibilityChecksRepoService))
        .factory('ng2DataPrivacyInfoTypeRepoService', downgradeInjectable(DataPrivacyInfoTypeRepoService))
        .factory('ng2AccessibilityGuidelinesRepoService', downgradeInjectable(AccessibilityGuidelinesRepoService))
        .factory('ng2AccessibilityRepoService', downgradeInjectable(AccessibilityRepoService))
        .factory('ng2MeReportingsRepoService', downgradeInjectable(MeReportingsRepoService))
        .factory('ng2AuditlogService', downgradeInjectable(AuditlogService))
        .factory('ng2ReportRepoService', downgradeInjectable(ReportRepoService))
        .factory('ng2AuditRepoService', downgradeInjectable(AuditRepoService))
        .factory('ng2GuidelineInfoService', downgradeInjectable(GuidelineInfoService))
        .factory('UserRepo', downgradeInjectable(UserRepoService))
        .factory('ng2UserService', downgradeInjectable(UserService))
        .factory('ng2PageRepoService', downgradeInjectable(PageRepoService))
        .factory('ng2DemoStatsRepoService', downgradeInjectable(DemoStatsRepoService))
        .factory('ng2QualityRepoService', downgradeInjectable(QualityRepoService))
        .factory('ng2ExtensionService', downgradeInjectable(ExtensionService))
        .factory('ng2StatsRepoService', downgradeInjectable(StatsRepoService))
        .factory('ng2RedirectWhitelistHelperService', downgradeInjectable(RedirectWhitelistHelperService))
        .factory('ng2AccessibilityCheckNameService', downgradeInjectable(AccessibilityCheckNameService))
        .factory('ng2FormDomainScriptCookieBannerService', downgradeInjectable(FormDomainScriptCookieBannerService))
        .factory('ng2ProfilesRepoService', downgradeInjectable(ProfilesRepoService))
        .factory('ng2PagePerformanceRescanRepoService', downgradeInjectable(PagePerformanceRescanRepoService))
        .factory('ng2HeatmapsRepoService', downgradeInjectable(HeatmapsRepoService))
        .factory('ng2HelpCenterRepoService', downgradeInjectable(HelpCenterRepoService))
        .factory('ng2AccountRepoService', downgradeInjectable(AccountRepoService))
        .factory('ng2PagePerformanceReportRepoService', downgradeInjectable(PagePerformanceReportRepoService))
        .factory('ng2PagePerformanceResultsRepoService', downgradeInjectable(PagePerformanceResultsRepoService))
        .factory('ng2ReadabilityRepoService', downgradeInjectable(ReadabilityRepoService))
        .factory('ng2SeoRepoService', downgradeInjectable(SeoRepoService))
        .factory('ng2FormDomainScriptGuideService', downgradeInjectable(FormDomainScriptGuideService))
        .factory('ng2DomainGroupRepoService', downgradeInjectable(DomainGroupRepoService))
        .factory('ng2PageClarityRepoService', downgradeInjectable(PageClarityRepoService))
        .factory('ng2ClarityRepoService', downgradeInjectable(ClarityRepoService))
        .factory('ng2ClarityService', downgradeInjectable(ClarityService))
        .factory('ng2PageClarityService', downgradeInjectable(PageClarityService))
        .factory('ng2ChangesHistoryReadabilityService', downgradeInjectable(ChangesHistoryReadabilityService))
        .factory('ng2DataPrivacyLineChartService', downgradeInjectable(DataPrivacyLineChartService))
        .factory('ng2PolicyLineChartService', downgradeInjectable(PolicyLineChartService))
        .factory('ng2QALineChartService', downgradeInjectable(QALineChartService))
        .factory('ng2ReadabilityLineChartService', downgradeInjectable(ReadabilityLineChartService))
        .factory('ng2LabelRepo', downgradeInjectable(LabelRepo))
        .factory('ng2BrokenPageRepoService', downgradeInjectable(BrokenPageRepoService))
        .factory('ng2PageAccessibilityRepoService', downgradeInjectable(PageAccessibilityRepoService))
        .factory('ng2PagePolicyRepoService', downgradeInjectable(PagePolicyRepoService))
        .factory('ng2LinkPagesRepoService', downgradeInjectable(LinkPagesRepoService))
        .factory('ng2ShortcutsRepoService', downgradeInjectable(ShortcutsRepoService))
        .factory('ng2PdfRepoService', downgradeInjectable(PdfRepoService))
        .factory('ng2PagePerformanceAuditDetailsService', downgradeInjectable(PagePerformanceAuditDetailsService))
        .factory('ng2DisplayHelperService', downgradeInjectable(DisplayHelperService))
        .factory('ng2PagePerformanceProfileService', downgradeInjectable(PagePerformanceProfileService))
        .factory('ng2AccessibilityAuxService', downgradeInjectable(AccessibilityAuxService))
        .factory('ng2ChangesHistoryHelperService', downgradeInjectable(ChangesHistoryHelperService))
        .factory('ng2DemoHeatmapsRepoService', downgradeInjectable(DemoHeatmapsRepoService))
        .factory('ng2AdminDomainsService', downgradeInjectable(AdminDomainsService))
        .factory('ng2FastTrackTableService', downgradeInjectable(FastTrackTableService))
        .factory('ng2GlobalPolicyExchangeCenterTemplateService', downgradeInjectable(GlobalPolicyExchangeCenterTemplateService))
        .factory('ng2CoreDomainService', downgradeInjectable(CoreDomainService))
        .factory('ng2CrawlsRepoService', downgradeInjectable(CrawlsRepoService))
        .factory('ng2SpellingRepoService', downgradeInjectable(SpellingRepoService))
        .factory('ng2CookiesRepoService', downgradeInjectable(CookiesRepoService))
        .factory('ng2PolicyRepoService', downgradeInjectable(PolicyRepoService))
        .factory('ng2PageFixRepoService', downgradeInjectable(PageFixRepoService))
        .factory('ng2SupportAccessibilityChecksRepoService', downgradeInjectable(SupportAccessibilityChecksRepoService))
        .factory('ng2TrapFocusService', downgradeInjectable(TrapFocusService))
        .factory('ng2EditAltTextService', downgradeInjectable(EditAltTextService))
        .factory('ng2CrawlHistoryInfoService', downgradeInjectable(CrawlHistoryInfoService))
        .factory('ng2PagePageLinksRepoService', downgradeInjectable(PagePageLinksRepoService))
        .factory('ng2PageFixAccessibilityConfigService', downgradeInjectable(PageFixAccessibilityConfigService))
        .factory('ng2LinksRepoService', downgradeInjectable(LinksRepoService))
        .factory('ng2ComplianceAccessibilityService', downgradeInjectable(ComplianceAccessibilityService))
        .factory('ng2PagePerformanceService', downgradeInjectable(PagePerformanceService))
        .factory('ng2PagePerformancePageDetailsService', downgradeInjectable(PagePerformancePageDetailsService))
        .factory('ng2AccessibilityAutofixService', downgradeInjectable(AccessibilityAutofixService))
        .factory('ng2DataPrivacyViolationRepoService', downgradeInjectable(DataPrivacyViolationRepoService))
        .factory('ng2DataPrivacyContentRepoService', downgradeInjectable(DataPrivacyContentRepoService))
        .factory('ng2DataPrivacyService', downgradeInjectable(DataPrivacyService))
        .factory('ng2SEOLineChartFactory', downgradeInjectable(SEOLineChartFactory))
        .factory('ng2FormPagePerformancePageService', downgradeInjectable(FormPagePerformancePageService))
        .factory('ng2QAReadabilityService', downgradeInjectable(QAReadabilityService))
        .factory('ng2PagePerformanceAuditInfoService', downgradeInjectable(PagePerformanceAuditInfoService))
        .factory('ng2LightDomainPageFixDashboardPageFixModelService', downgradeInjectable(LightDomainPageFixDashboardPageFixModelService))
        .factory('ng2CookieModelService', downgradeInjectable(CookieModelService))
        .factory('ng2LoadingService', downgradeInjectable(LoadingService))
        .factory('ng2CookieCategoriesLocalizeService', downgradeInjectable(CookieCategoriesLocalizeService))
        .factory('ng2PageFixAccessibilityDifficultyService', downgradeInjectable(PageFixAccessibilityDifficultyService))
        .factory('ng2PagePerformanceVersionService', downgradeInjectable(PagePerformanceVersionService))
        .factory('ng2LanguagesService', downgradeInjectable(LanguagesService))
        .factory('ng2PagePerformanceAuditService', downgradeInjectable(PagePerformanceAuditService))
        .factory('ng2PagePerformanceChartsService', downgradeInjectable(PagePerformanceChartsService))
    ;

    angular.module('modules.data_privacy.services')
        .factory('ng2DataPrivacylikelihoodService', downgradeInjectable(DataPrivacyLikelihoodService));

    angular.module('core.services.error-handler')
        .factory('ng2ErrorHandler', downgradeInjectable(ErrorHandler));

    angular.module('modules.demodata')
        .factory('ng2DemoHasAccessService', downgradeInjectable(DemoHasAccessService));

    angular.module('app.components.charts')
        .factory('ng2LegendCursorService', downgradeInjectable(LegendCursorService));

    angular.module('blocks.helpers')
        .factory('ng2HistoryHelperService', downgradeInjectable(HistoryHelperService));

    angular.module('filters.monsido')
        .factory('ng2PropertiesSumService', downgradeInjectable(PropertiesSumService));

    angular.module('modules.light.services')
        .factory('ng2LightDomainViewService', downgradeInjectable(LightDomainViewService));

    angular
        .module('modules.page_fix.services')
        .factory('ng2PageFixFormHtmlElementOptionsService', downgradeInjectable(PageFixFormHtmlElementOptionsService));

    angular.module('services.entities')
        .factory('ng2DomainService', downgradeInjectable(DomainService));

    angular.module('modules.data_privacy.services.api')
        .factory('ng2DataPrivacyRulesetRepo', downgradeInjectable(DataPrivacyRulesetRepo))
        .factory('ng2DataPrivacySummaryRepo', downgradeInjectable(DataPrivacySummaryRepo));

    angular.module('blocks.service')
        .factory('ng2UserLocaleService', downgradeInjectable(UserLocaleService));
})();
