(function () {
    'use strict';
    angular.module('blocks.service').factory('complianceService', complianceService);

    complianceService.$inject = ['ng2ComplianceAccessibilityService'];
    /* @ngInject*/
    function complianceService (ng2ComplianceAccessibilityService) {
        return {
            qaHistoryCompliance: qaHistoryCompliance,
            accessibilityHistoryCompliance: accessibilityHistoryCompliance,
            seoHistoryCompliance: seoHistoryCompliance,
            policyHistoryCompliance: policyHistoryCompliance,
            accessibilityCompliance: accessibilityCompliance,
            accessibilityCompliancePercentageFromResultType: accessibilityCompliancePercentageFromResultType,
            seoCompliance: seoCompliance,
            seoCompliancePercentage: seoCompliancePercentage,
            policyCompliance: policyCompliance,
            policyCompliancePercentage: policyCompliancePercentage,
        };

        function qaHistoryCompliance (history) {
            if (history.compliance_percentage && history.compliance_percentage.qa_pages) {
                return [history.compliance_percentage.qa_pages, 100 - history.compliance_percentage.qa_pages];
            }
            return false;
        }

        function accessibilityHistoryCompliance (history) {
            if (history.compliance_percentage && history.compliance_percentage.accessibility_pages) {
                return [
                    history.compliance_percentage.accessibility_pages.error_and_warning_and_review,
                    100 - history.compliance_percentage.accessibility_pages.error_and_warning_and_review,
                ];
            }
            return false;
        }

        function seoHistoryCompliance (history) {
            if (history.compliance_percentage && history.compliance_percentage.seo_issues) {
                return [history.compliance_percentage.seo_issues, 100 - history.compliance_percentage.seo_issues];
            }
            return false;
        }

        function policyHistoryCompliance (history) {
            if (history.compliance_percentage && history.compliance_percentage.policies) {
                return [history.compliance_percentage.policies, 100 - history.compliance_percentage.policies];
            }
        }

        function accessibilityCompliance (resultType, pageCountAttr, accessibilityChecks, summary) {
            var compliance = ng2ComplianceAccessibilityService.getPercentageFromResultType(
                resultType,
                pageCountAttr,
                accessibilityChecks,
                summary,
            );
            return [compliance, 100 - compliance];
        }

        function policyCompliance (summary, policies, contentWithErrorsCount, pagesCount, linksCount) {
            var percentage = policyCompliancePercentage(summary, policies, contentWithErrorsCount, pagesCount, linksCount);
            var contentCount = (summary.page_count || 0) + (summary.documents_count || 0);
            return [percentage, 100 - percentage];

        }

        function policyCompliancePercentage (summary, policies, contentWithErrorsCount, pagesCount, linksCount) {
            var score;
            var contentWithErrors = policies.reduce(function (num, policy) {
                if (policy.subject === 'link') {
                    return num + (parseInt(policy.matches_count) / linksCount) * 100;
                } else {
                    return num + (parseInt(policy.matches_count) / pagesCount) * 100;
                }
            }, 0);
            score = 100 - contentWithErrors / policies.length;

            if (isNaN(score)) {
                return 0;
            }

            return score;
        }

        function accessibilityCompliancePercentageFromResultType (resultTypes, pageCountAttr, accessibilityChecks, summary) {
            var score;
            score = ng2ComplianceAccessibilityService.getPercentageFromResultType(
                resultTypes,
                pageCountAttr,
                accessibilityChecks,
                summary,
            );

            if (isNaN(score)) {
                return 0;
            }

            return score;
        }

        function seoCompliance (summary, checkpoints, history) {
            var percentage = seoCompliancePercentage(summary, checkpoints, history);
            return [percentage, 100 - percentage];
        }

        function seoCompliancePercentage (summary, checkpoints, history) {
            return summary.compliance_percentage;
        }
    }
})();
