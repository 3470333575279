import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityIssues', {
        templateUrl: 'app/pages/accessibility/issues/issues.html',
        controller: AccessibilityIssuesController,
        controllerAs: 'vm',
    });

    AccessibilityIssuesController.$inject = [
        'ng2SessionService',
        'gettextCatalog',
        'activeFeatures',
        '$filter',
        '$stateParams',
        'Lodash',
        'ng2PageRepoService',
        'ng2LabelRepo',
        'AccessibilityFiltersService',
        'ng2CrawlHistoryInfoService',
    ];
    /* @ngInject */
    function AccessibilityIssuesController (
        ng2SessionService,
        gettextCatalog,
        activeFeatures,
        $filter,
        $stateParams,
        Lodash,
        ng2PageRepoService,
        ng2LabelRepo,
        AccessibilityFiltersService,
        ng2CrawlHistoryInfoService,
    ) {
        var vm = this;
        let accessibilityErrorsOnly = true;
        vm.destroyed$ = new Subject();
        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.onFilterChange = onFilterChange;
        vm.onSearch = onSearch;
        vm.getLabels = getLabels;

        function activate () {
            ng2CrawlHistoryInfoService.accessibilityErrorsOnly$
                .pipe(takeUntil(vm.destroyed$))
                .subscribe((value) => {
                    accessibilityErrorsOnly = value;
                });
            vm.search = '';
            vm.showFilters = activeFeatures.table_filters;
            vm.accessibility = ng2SessionService.domain.features.accessibility;
            const indexOfVersion = vm.accessibility.search(/_v(\d+)?/);
            /* Makes accessibility guidelines independent of version for displaying */
            if (indexOfVersion > -1) {
                vm.accessibility = vm.accessibility.slice(0, indexOfVersion);
            }
            vm.accessibilityAbbrs = getAccessibilityOptions();
            vm.pageCount = 0;
            vm.labels = [];
            vm.selectedLabels = [];

            var level = $stateParams.abbr;
            var abbrs = Lodash.map(vm.accessibilityAbbrs, 'value');

            if ($filter('accessibilityHasGuideline')('wcag2')) {
                vm.header = gettextCatalog.getString('Accessibility Checklist (WCAG 2.0)');
            } else if ($filter('accessibilityHasGuideline')('wcag21')) {
                vm.header = gettextCatalog.getString('Accessibility Checklist (WCAG 2.1)');
            } else if ($filter('accessibilityHasGuideline')('wcag22')) {
                vm.header = gettextCatalog.getString('Accessibility Checklist (WCAG 2.2)');
            } else if ($filter('accessibilityHasGuideline')('qualweb')) {
                vm.header = gettextCatalog.getString('Accessibility Checklist (QUALWEB)');
            }
            if (abbrs.indexOf(level) > -1) {
                vm.activeTab = level;
            } else {
                vm.activeTab = Lodash.head(abbrs);
            }
            setPageCount();
            setupFilters();
            getLabels();
            hasLevelComplianceCheck();
        }

        function onDestroy () {
            vm.destroyed$.next();
            vm.destroyed$.complete();
        }

        function getLabels () {
            var params = {
                page_size: 1000,
            };

            ng2LabelRepo.getLabels(params).then(function (labels) {
                vm.labels = labels;
                vm.availableFilters[gettextCatalog.getString('Labels')] = labels;
            }, angular.noop);
        }

        function setPageCount () {
            var params = {
                page: 1,
                page_size: 1,
                type: 'html',
            };

            ng2PageRepoService.getAll(params).then(function (data) {
                vm.pageCount = parseInt(data.total, 10);
            }, angular.noop);
        }

        function setupFilters () {
            vm.accessibilityType = [];
            vm.filters = {};
            vm.selectedResponsibility = '';
            vm.filters[gettextCatalog.getString('Accessibility error type')] = [];
            vm.filters[gettextCatalog.getString('Labels')] = [];
            vm.filters[AccessibilityFiltersService.getReponsibilityTranslation()] = [];
            vm.availableFilters = {};
            vm.availableFilters[AccessibilityFiltersService.getReponsibilityTranslation()] =
                AccessibilityFiltersService.getResponsibilities();
            vm.availableFilters[gettextCatalog.getString('Labels')] = [];
            vm.availableFilters[gettextCatalog.getString('Accessibility error type')] = [];

            if (!accessibilityErrorsOnly) {
                vm.availableFilters[gettextCatalog.getString('Accessibility error type')] = [
                    {
                        name: gettextCatalog.getString('Error'),
                        value: 'error',
                    },
                    {
                        name: gettextCatalog.getString('Warning'),
                        value: 'warning',
                    },
                    {
                        name: gettextCatalog.getString('Review'),
                        value: 'review',
                    },
                ];
            }

            if (AccessibilityFiltersService.canUseMobileGuidelines()) {
                vm.availableFilters[gettextCatalog.getString('Accessibility error type')].push({
                    name: gettextCatalog.getString('Mobile only'),
                    value: 'mobile',
                });
            }
        }

        function getAccessibilityOptions () {
            var options = [];
            var levelA = { name: gettextCatalog.getString('Level A'), value: 'A' };
            var levelAA = { name: gettextCatalog.getString('Level AA'), value: 'AA' };
            var levelAAA = { name: gettextCatalog.getString('Level AAA'), value: 'AAA' };
            switch (vm.accessibility) {
                case 'QUALWEB-A':
                case 'WCAG2-A':
                case 'WCAG21-A':
                case 'WCAG21-A_mobile':
                case 'WCAG22-A':
                case 'WCAG22-A_mobile':
                    options = [levelA];
                    break;

                case 'QUALWEB-AA':
                case 'WCAG2-AA':
                case 'WCAG21-AA':
                case 'WCAG21-AA_mobile':
                case 'WCAG22-AA':
                case 'WCAG22-AA_mobile':
                    options = [levelA, levelAA];
                    break;

                case 'WCAG2-AAA':
                case 'WCAG21-AAA':
                case 'WCAG21-AAA_mobile':
                case 'WCAG22-AAA':
                case 'WCAG22-AAA_mobile':
                    options = [levelA, levelAA, levelAAA];
                    break;
            }

            if (!ng2SessionService.isDomainGroup()) {
                options.push({ name: gettextCatalog.getString('Ignored'), value: 'ignored' });
            }

            return options.concat([{ name: gettextCatalog.getString('Passed Checks'), value: 'passed' }]);
        }

        function onFilterChange (changes) {
            if (!changes) {
                return;
            }
            vm.accessibilityType = changes[gettextCatalog.getString('Accessibility error type')].map(function (data) {
                return data.value;
            });
            vm.selectedLabels = changes[gettextCatalog.getString('Labels')].map(function (label) {
                return label.id;
            });

            vm.selectedResponsibility = changes[AccessibilityFiltersService.getReponsibilityTranslation()].map(function (responsibility) {
                vm.filters[AccessibilityFiltersService.getReponsibilityTranslation()] = [responsibility];
                return responsibility.value;
            });
        }

        function onSearch (search) {
            vm.search = search;
        }

        function hasLevelComplianceCheck () {
            vm.hasLevelACompliance = $filter('accessibilityHasGuidelineId')('wcag21-a') || $filter('accessibilityHasGuidelineId')('wcag21-a_mobile');
            vm.hasLevelAACompliance = $filter('accessibilityHasGuidelineId')('wcag21-aa') || $filter('accessibilityHasGuidelineId')('wcag21-aa_mobile');
            vm.hasLevelAAACompliance = $filter('accessibilityHasGuidelineId')('wcag21-aaa') || $filter('accessibilityHasGuidelineId')('wcag21-aaa_mobile');
            vm.hasLevel22ACompliance = $filter('accessibilityHasGuidelineId')('wcag22-a') || $filter('accessibilityHasGuidelineId')('wcag22-a_mobile');
            vm.hasLevel22AACompliance = $filter('accessibilityHasGuidelineId')('wcag22-aa') || $filter('accessibilityHasGuidelineId')('wcag22-aa_mobile');
            vm.hasLevel22AAACompliance = $filter('accessibilityHasGuidelineId')('wcag22-aaa') || $filter('accessibilityHasGuidelineId')('wcag22-aaa_mobile');
        }
    }
})();
